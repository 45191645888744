import React from 'react'
import { withAuthenticated } from '../components/authenticated'

class Index extends React.Component {
  componentDidMount () {
    window.location = '/v2/dashboard'
  }

  render () {
    return null
  }
}

export default withAuthenticated(Index)
