import React from 'react'
import { connect } from 'react-redux'
import Loading from './loading'
import {
  verifyCurrentUser,
  getAccessPolicies,
  authenticate
} from '../lib/redux/actions'

class LoadResources extends React.Component {
  componentDidMount () {
    const { verifyCurrentUser, getAccessPolicies, currentUser, authenticate } =
      this.props

    if (currentUser) return

    verifyCurrentUser()
      .then(response => {
        if (response.lockedAt || response.deletedAt) {
          window.location = '/logout?redirect=' + location.href
          return
        }

        authenticate(response)
        Promise.all([getAccessPolicies()])
      })
      .catch(error => {
        window.location = '/logout?redirect=' + location.href
        return error
      })
  }

  render () {
    const { children, currentUser, accessPolicies } = this.props
    if (!currentUser || Object.keys(accessPolicies).length === 0) {
      return <Loading />
    }

    return children(currentUser, accessPolicies)
  }
}

function mapStateToProps (state) {
  const currentUser = state.currentUser
  const accessPolicies = state.accessPolicies

  return {
    currentUser,
    accessPolicies
  }
}

const mapDispatchToProps = {
  verifyCurrentUser,
  getAccessPolicies,
  authenticate
}

const Authenticated = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadResources)

export function withAuthenticated (Component) {
  return function AuthenticatedDecorator (props) {
    return (
      <Authenticated>
        {currentUser => <Component {...props} currentUser={currentUser} />}
      </Authenticated>
    )
  }
}

export default Authenticated
