import React from 'react'

class Loading extends React.Component {
  render () {
    const { children } = this.props

    return (
      <div className='loading'>
        <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
          <span className='lead text-center'>
            <img src='/loader.gif' alt='' />
            <br />
            {children}
          </span>
        </div>
      </div>
    )
  }
}

export default Loading
